import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BankFormProviderService, CardFormProviderService, FormValidatorService, DiscardComponent, paymentDetailsValidatorFn, RegistrationService } from '@prcins/utils';
import { FormBuilder, Validators } from '@angular/forms';
import { CURRENT_POLICY, QPAY_PAYMENT_DETAILS, USER_ID, CURRENT_POLICY_LOB, CURRENT_POLICY_PRODUCT_CODE, WALLET_APP_NAME, PAYMENT_SOURCE, ADMIN_QPAY_TOKEN, SCHEDULED_PAY_PLANS, CURRENT_POLICY_PAY_PLAN, QPAY_REGISTRATION_EMAIL } from '@prcins/constants';
import { PaymentProviderService } from '../services/payment-provider.service';
import { switchMap, tap, filter } from 'rxjs/operators';
import { of, iif, merge, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { ScrollToInvalidService } from '../services/scroll-to-invalid.service';
import { RightRailService } from '../../../../utils/src/lib/services/right-rail.service';
var PaymentEntryFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentEntryFormComponent, _super);
    function PaymentEntryFormComponent(fb, router, route, bankFormProvider, cardFormProvider, paymentProvider, formValidator, managePaymentsProviderService, scrollToInvalidService, registerationService, rightRailServiceProvider) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.router = router;
        _this.route = route;
        _this.bankFormProvider = bankFormProvider;
        _this.cardFormProvider = cardFormProvider;
        _this.paymentProvider = paymentProvider;
        _this.formValidator = formValidator;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.scrollToInvalidService = scrollToInvalidService;
        _this.registerationService = registerationService;
        _this.rightRailServiceProvider = rightRailServiceProvider;
        _this.hydrateFormSubject$ = new BehaviorSubject(false);
        _this.hydrateFormObservable = _this.hydrateFormSubject$.asObservable();
        _this.paymentMethod = {
            eft: false,
            cc: false
        };
        _this.submitted = false;
        _this.paymentForm = _this.fb.group({
            confirmationEmail: [''],
            paymentAmountType: ['%%', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\-_]*$/)]],
            paymentAmount: [
                '',
                [
                    Validators.required,
                    Validators.min(0.01),
                    Validators.max(10000),
                    _this.paymentAmountValidator()
                ]
            ],
            paymentOptionSelected: ['', Validators.required],
            ccSavedPaymentMethod: [''],
            eftSavedPaymentMethod: [''],
            savedCardSecurityCode: ['', Validators.required],
            saveThisBank: [''],
            saveThisCard: [''],
            eftForm: _this.bankFormProvider.getForm(),
            cardForm: _this.cardFormProvider.getForm()
        }, { validators: [paymentDetailsValidatorFn()] });
        _this.underwriterCode = "ALN_UND";
        _this.isPaymentDetailsLoaded = false;
        _this.isReviewInProgress = false;
        _this.invalidCard = false;
        _this.showPaymentImpMsg = false;
        _this.addNewCard = false;
        _this.addNewBank = false;
        _this.showSpinner = false;
        _this.featureFlags = [];
        _this.isWalletFlagEnabled = false;
        _this.helps = {
            paymentAmount: false,
            savedCardSecurityCode: false
        };
        _this.helpFlags = tslib_1.__assign({}, _this.helps);
        _this.validCardEntry$ = of(_this.paymentForm).pipe(switchMap(function (form) {
            return _this.paymentProvider.encryptPaymentDetails(form.value, _this.policyDetails.policyNumber);
        }), tap(function (encrypt) { return _this.savePdAndNavigate(encrypt); }));
        _this.invalidCardEntry$ = of({}).pipe(tap(function () {
            _this.invalidCard = true;
            _this.cardNotification = { type: 'ERROR', message: 'Invalid Credit / Debit card information.' };
        }));
        _this.cardEntry$ = of(_this.paymentForm).pipe(filter(function (form) { return form.valid && form.value.paymentOptionSelected === 'cc' && _this.addNewCard; }), switchMap(function (form) {
            var _a = _this.policyDetails, policyNumber = _a.policyNumber, companyCode = _a.companyCode, productCd = _a.productCode, branchCode = _a.branchCode;
            var _b = _this.addressDetails, policyState = _b.state, policyZip = _b.zipCode;
            return _this.paymentProvider.validateCard({
                policyNumber: policyNumber,
                policyState: policyState,
                uwCompany: companyCode,
                productCd: productCd,
                policyZip: policyZip,
                cardNumber: form.value.cardForm.cardNumber,
                branchCd: branchCode
            }, 
            //this.underwriterCode
            "ALN_UND");
        }), switchMap(function (validateResp) {
            return iif(function () { return validateResp.valid === false; }, _this.invalidCardEntry$, _this.validCardEntry$);
        }));
        _this.savedCardEntry$ = of(_this.paymentForm).pipe(filter(function () { return _this.paymentForm.value.paymentOptionSelected === 'cc' && !_this.addNewCard; }), tap(function (form) {
            form.controls.cardForm.enable();
            _this.fillUserInformation(_this.billingDetails.insuredName);
        }), tap(function (form) { return form.value.savedPaymentMethod = _this.storedWalletMap[form.value.ccSavedPaymentMethod]; }), switchMap(function (form) {
            return _this.paymentProvider.encryptPaymentDetails(form.value, _this.policyDetails.policyNumber);
        }), tap(function (encrypt) { return _this.savePdAndNavigate(encrypt); }));
        _this.electronicEntry$ = of(_this.paymentForm).pipe(filter(function (form) { return form.valid && form.value.paymentOptionSelected === 'eft' && _this.addNewBank; }), switchMap(function (form) {
            return _this.paymentProvider.encryptPaymentDetails(form.value, _this.policyDetails.policyNumber);
        }), tap(function (encrypt) { return _this.savePdAndNavigate(encrypt); }));
        _this.savedBankEntry$ = of(_this.paymentForm).pipe(filter(function () { return _this.paymentForm.value.paymentOptionSelected === 'eft' && !_this.addNewBank; }), tap(function (form) {
            form.controls.eftForm.enable();
            _this.fillUserInformation(_this.billingDetails.insuredName);
        }), tap(function (form) { return form.value.savedPaymentMethod = _this.storedWalletMap[form.value.eftSavedPaymentMethod]; }), switchMap(function (form) {
            return _this.paymentProvider.encryptPaymentDetails(form.value, _this.policyDetails.policyNumber);
        }), tap(function (encrypt) { _this.savePdAndNavigate(encrypt); }));
        _this.invalidEntry$ = of(_this.paymentForm).pipe(filter(function (form) { return (_this.addNewBank || _this.addNewCard) && !form.valid; }), tap(function (form) { return form.markAllAsTouched(); }));
        _this.storedCreditCards = [];
        _this.storedBankAccounts = [];
        _this.disableEFT = false;
        _this.isDeferedEserviceRegisteration = false;
        _this.showIntermediateRegisterationForm = false;
        _this.defaultCreditCardWallet = null;
        _this.defaultBankAccountWallet = null;
        _this.route.queryParams.subscribe(function (params) {
            var isGuestQpayLogin = sessionStorage.getItem(PAYMENT_SOURCE) ? sessionStorage.getItem(PAYMENT_SOURCE) === 'qpay' : false;
            if (isGuestQpayLogin) {
                var policyNumber = sessionStorage.getItem(CURRENT_POLICY);
                _this.registerationService.checkIfPolicyAlreadyRegisteredInEservice(policyNumber).subscribe(function (resp) {
                    if (resp) {
                        _this.showIntermediateRegisterationForm = false;
                    }
                    else {
                        _this.isDeferedEserviceRegisteration = params['enroll'] ? params['enroll'] === 'defer' : false;
                        if (_this.isDeferedEserviceRegisteration) {
                            _this.showIntermediateRegisterationForm = false;
                            sessionStorage.setItem("qPayEserviceEnroll", "defer");
                        }
                        else {
                            _this.showIntermediateRegisterationForm = true;
                            sessionStorage.removeItem("qPayEserviceEnroll");
                        }
                        sessionStorage.removeItem(QPAY_REGISTRATION_EMAIL);
                    }
                });
            }
        });
        return _this;
    }
    PaymentEntryFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (sessionStorage.getItem('qPayEmail')) {
            this.paymentForm.patchValue({ 'confirmationEmail': sessionStorage.getItem('qPayEmail') });
        }
        else if (sessionStorage.getItem('userId')) {
            this.paymentForm.patchValue({ 'confirmationEmail': sessionStorage.getItem('userId') });
        }
        this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        this.rightRailServiceProvider.getFlagsFromCache(this.policyNumber).subscribe(function (data) {
            _this.isWalletFlagEnabled = data;
        });
        this.deactivateGuardSelection$.subscribe(function (selection) {
            if (selection) {
                _this.paymentProvider.resetACHFailureCount();
            }
        });
        this.showSpinner = true;
        this.setupPaymentForms();
        var validStatus = ["Cancel", "Canceled", "NonRenewed"];
        this.route.queryParams.subscribe(function (params) {
            var mode = params.m, amount = params.amt;
            switch (mode) {
                case '1':
                    _this.paymentForm.get('paymentAmountType').patchValue('balance');
                    break;
                case '2':
                    _this.paymentForm.get('paymentAmountType').patchValue('minimum');
                    break;
                case '3':
                    _this.paymentForm.get('paymentAmountType').patchValue('other');
                    if (Number(amount)) {
                        _this.paymentForm.get('paymentAmount').patchValue(amount);
                    }
                    break;
                default:
                    _this.paymentForm.get('paymentAmountType').patchValue('minimum');
            }
        });
        this.paymentForm.valueChanges.subscribe(function (e) {
            _this.submitted = false;
        });
        var lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
        var productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
        this.payInFullFlag = (lob == 'HO' || productCode == 'PCAT' || productCode == 'ALN_UMB') ? false : true;
        this.paymentProvider.getPaymentDetails(this.policyNumber).subscribe(function (val) {
            _this.billingDetails = val.billingDetails;
            _this.policyDetails = val.policyDetails.product;
            _this.addressDetails = val.policyDetails.primaryAddress;
            //this.underwriterCode = val.policySearchInfo.underwriterCode;
            _this.underwriterCode = "ALN_UND";
            _this.balanceDue = _this.getBalanceDue();
            _this.coverageThrough = _this.getBalanceTillDt();
            _this.populatePaymentAmount();
            _this.hydratePaymentForm(_this.policyNumber);
            _this.paymentDetails = {
                "amountDue": _this.billingDetails.nextPaymentAmnt,
                "paymentDueDt": _this.billingDetails.nextPaymentDueDt,
                "landingPage": true,
                "lastPaymentDate": _this.billingDetails.lastPaymentDt,
                "pendingCancellation": _this.policyDetails.cancelPending === "Yes" ? true : false
            };
            sessionStorage.setItem(CURRENT_POLICY_PAY_PLAN, _this.billingDetails.payplan);
            if (_this.contains(validStatus, _this.policyDetails["cancelPolicyStatus"])) {
                _this.showPaymentImpMsg = true;
            }
            if (sessionStorage.getItem("showPayImpMsg")) {
                _this.showPaymentImpMsg = false;
            }
        });
        this.storedCreditCards = [];
        this.storedBankAccounts = [];
        this.storedWalletMap = {};
        var app = sessionStorage.getItem(PAYMENT_SOURCE);
        app = (app) ? app : WALLET_APP_NAME;
        var isQPayAdmin = sessionStorage.getItem(ADMIN_QPAY_TOKEN); //QPtrue
        this.paymentSavingAllowed = (isQPayAdmin === 'QPtrue') || (isQPayAdmin === String(true)) || app === WALLET_APP_NAME;
        this.hydrateFormObservable.subscribe(function (isFormHydrated) {
            if (isFormHydrated) {
                _this.autoSelectForm(_this.paymentD);
                _this.managePaymentsProviderService.getPolicyPaymentDetails(sessionStorage.getItem(USER_ID), _this.policyNumber, app).subscribe(function (wallets) {
                    if (wallets) {
                        wallets.forEach(function (wallet) {
                            _this.storedWalletMap[wallet.walletId] = wallet;
                            if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
                                wallet.selected = _this.paymentForm.value.ccSavedPaymentMethod === wallet.walletId;
                                _this.storedCreditCards.push(wallet);
                                if (wallet.defaultInd === "Yes" || wallet.selected) {
                                    _this.defaultCreditCardWallet = wallet;
                                }
                            }
                            else if (wallet.paymentType === "ACH") {
                                wallet.selected = _this.paymentForm.value.eftSavedPaymentMethod === wallet.walletId;
                                _this.storedBankAccounts.push(wallet);
                                if (wallet.defaultInd === "Yes" || wallet.selected) {
                                    _this.defaultBankAccountWallet = wallet;
                                }
                            }
                        });
                    }
                    // NO NOIC
                    // If any Default Bank Account - EFT
                    // If any Default Card - CC
                    // If any Saved Bank Account - EFT
                    // If any Saved Card - CC
                    // ELSE
                    // Default to Bank
                    // NOIC
                    // If any Default Card - CC
                    // If any Default Bank Account - EFT
                    // If any Saved Card - CC
                    // If any Saved Bank Account - EFT
                    // ELSE
                    // Default to Card
                    if (_this.paymentDetails.pendingCancellation) {
                        if (_this.defaultCreditCardWallet) {
                            _this.onTogglePaymentMethod('cc');
                            _this.savedPaymentMethodChanged({ 'key': 'ccSavedPaymentMethod', 'value': _this.defaultCreditCardWallet.walletId }, 'cc');
                        }
                        else if (_this.defaultBankAccountWallet) {
                            _this.onTogglePaymentMethod('eft');
                            _this.savedPaymentMethodChanged({ 'key': 'eftSavedPaymentMethod', 'value': _this.defaultBankAccountWallet.walletId }, 'eft');
                        }
                        else if (wallets && wallets.some(function (wallet) { return wallet.paymentType === "CC" || wallet.paymentType === "ATM"; })) {
                            _this.onTogglePaymentMethod('cc');
                        }
                        else if (wallets && wallets.some(function (wallet) { return wallet.paymentType === "ACH"; })) {
                            _this.onTogglePaymentMethod('eft');
                        }
                        else {
                            _this.onTogglePaymentMethod('cc');
                        }
                    }
                    else {
                        if (_this.defaultBankAccountWallet) {
                            _this.onTogglePaymentMethod('eft');
                            _this.savedPaymentMethodChanged({ 'key': 'eftSavedPaymentMethod', 'value': _this.defaultBankAccountWallet.walletId }, 'eft');
                        }
                        else if (_this.defaultCreditCardWallet) {
                            _this.onTogglePaymentMethod('cc');
                            _this.savedPaymentMethodChanged({ 'key': 'ccSavedPaymentMethod', 'value': _this.defaultCreditCardWallet.walletId }, 'cc');
                        }
                        else if (wallets && wallets.some(function (wallet) { return wallet.paymentType === "ACH"; })) {
                            _this.onTogglePaymentMethod('eft');
                        }
                        else if (wallets && wallets.some(function (wallet) { return wallet.paymentType === "CC" || wallet.paymentType === "ATM"; })) {
                            _this.onTogglePaymentMethod('cc');
                        }
                        else {
                            _this.onTogglePaymentMethod('eft');
                        }
                    }
                    var cardNumber = _this.paymentForm.get('cardForm').get('cardNumber').value;
                    if (cardNumber && cardNumber.length > 0) {
                        _this.paymentForm.markAllAsTouched();
                    }
                    var bankAccountNumber = _this.paymentForm.get('eftForm').get('accountNumber').value;
                    if (bankAccountNumber && bankAccountNumber.length > 0) {
                        _this.paymentForm.markAllAsTouched();
                    }
                    if (_this.paymentProvider.getACHFailureCount() > 2) {
                        _this.paymentForm.get('eftForm').disable();
                        _this.paymentForm.get('paymentOptionSelected').setValue('');
                        _this.paymentMethod['eft'] = false;
                        _this.disableEFT = true;
                    }
                    // this.scrollToInvalidService.scrollToFirstInvalidControl(this.paymentFormRef);
                    _this.showSpinner = false;
                }, function (error) {
                    console.log(error);
                    _this.showSpinner = false;
                });
            }
        });
        this.updateSafeToNavigate(false);
    };
    PaymentEntryFormComponent.prototype.populatePaymentAmount = function () {
        var paymentAmtType = this.paymentForm.get('paymentAmountType').value;
        var amount = 0;
        switch (paymentAmtType) {
            case 'balance':
                amount = this.getBalanceDue();
                break;
            case 'minimum':
                amount = this.billingDetails.nextPaymentAmnt;
                break;
        }
        if (Number(amount)) {
            this.paymentForm.get('paymentAmount').patchValue(amount);
        }
    };
    PaymentEntryFormComponent.prototype.setupPaymentForms = function () {
        var eftForm = this.paymentForm.get('eftForm');
        var cardForm = this.paymentForm.get('cardForm');
        if (!eftForm.get('accountHolderName')) {
            eftForm.addControl('accountHolderName', this.fb.control('', [Validators.required]));
        }
        if (!eftForm.get('accountEmailId')) {
            eftForm.addControl('accountEmailId', this.fb.control('', [Validators.required, Validators.email]));
        }
        if (!cardForm.get('cardHolderName')) {
            cardForm.addControl('cardHolderName', this.fb.control('', [Validators.required]));
        }
        if (!cardForm.get('cardHolderEmailId')) {
            cardForm.addControl('cardHolderEmailId', this.fb.control('', [Validators.required, Validators.email]));
        }
        if (!cardForm.get('securityCode')) {
            cardForm.addControl('securityCode', this.fb.control('', [Validators.required, Validators.minLength(3)]));
        }
        if (!cardForm.get('maskedSecurityCode')) {
            cardForm.addControl('maskedSecurityCode', this.fb.control('', [Validators.required, Validators.minLength(3)]));
        }
    };
    PaymentEntryFormComponent.prototype.hydratePaymentForm = function (policyNumber) {
        var _this = this;
        var paymentD = this.paymentProvider.getPolicyPaymentDetails();
        var insuredName = this.billingDetails.insuredName;
        if (!paymentD.paymentDetails) {
            this.paymentProvider.getPaymentDetailsFromSession(policyNumber).subscribe(function (paymentData) {
                _this.fillUserInformation(insuredName);
                if (paymentData) {
                    _this.paymentForm.patchValue(tslib_1.__assign({}, paymentData));
                    _this.paymentMethod[paymentData.paymentOptionSelected] = true;
                    paymentD.paymentDetails = paymentData;
                }
                else {
                    //this.fillUserInformation(insuredName);
                }
                _this.onPaymentOptionSelected();
                _this.isPaymentDetailsLoaded = true;
                _this.hydrateFormSubject$.next(true);
            }, function () {
                _this.fillUserInformation(insuredName);
                _this.hydrateFormSubject$.next(true);
            });
        }
        else {
            this.paymentForm.patchValue(tslib_1.__assign({}, paymentD.paymentDetails));
            this.paymentMethod[paymentD.paymentDetails.paymentOptionSelected] = true;
            this.onPaymentOptionSelected();
            this.isPaymentDetailsLoaded = true;
            this.hydrateFormSubject$.next(true);
        }
        this.paymentForm.updateValueAndValidity();
        this.paymentD = paymentD;
    };
    PaymentEntryFormComponent.prototype.fillUserInformation = function (nameInsured) {
        nameInsured = nameInsured || this.billingDetails.insuredName;
        this.paymentForm.patchValue({
            eftForm: {
                accountHolderName: nameInsured,
                accountEmailId: sessionStorage.getItem(USER_ID)
            },
            cardForm: {
                cardHolderName: nameInsured,
                cardHolderEmailId: sessionStorage.getItem(USER_ID)
            }
        });
    };
    PaymentEntryFormComponent.prototype.startNavigationFlow = function () {
        var _this = this;
        this.paymentProvider
            .encryptPaymentDetails(this.paymentForm.value, this.policyDetails.policyNumber)
            .subscribe(function (data) { return _this.savePdAndNavigate(data); });
    };
    PaymentEntryFormComponent.prototype.savePdAndNavigate = function (response) {
        var pd = response.pd;
        if (pd) {
            sessionStorage.setItem(QPAY_PAYMENT_DETAILS, pd);
        }
        this.navigateToReview();
    };
    PaymentEntryFormComponent.prototype.navigateToReview = function () {
        this.paymentProvider.setPaymentDetails(this.paymentForm.value);
        this.router.navigate(['../review'], {
            queryParams: this.route.snapshot.queryParams,
            relativeTo: this.route
        });
    };
    PaymentEntryFormComponent.prototype.getBalanceDue = function () {
        return Number(this.billingDetails.balance);
    };
    PaymentEntryFormComponent.prototype.getBalanceTillDt = function () {
        var cancelPolicyStatus = this.policyDetails.cancelPolicyStatus;
        return cancelPolicyStatus && 'cancel' === cancelPolicyStatus.toLowerCase()
            ? this.policyDetails.cancelEffectiveDate
            : this.billingDetails.expiryDate;
    };
    PaymentEntryFormComponent.prototype.onPaymentAmountSelected = function (type) {
        if (type === 'minimum') {
            this.paymentForm
                .get('paymentAmount')
                .setValue(this.billingDetails.nextPaymentAmnt);
        }
        else if (type === 'balance') {
            this.paymentForm.get('paymentAmount').setValue(this.balanceDue);
        }
        else if (type === 'pif') {
            this.paymentForm.get('paymentAmount').setValue(this.billingDetails.payInFullAmnt);
        }
        else {
            this.paymentForm.get('paymentAmount').setValue(0);
        }
        this.paymentForm.get('paymentAmount').markAsTouched();
    };
    PaymentEntryFormComponent.prototype.onPaymentOptionSelected = function () {
        this.paymentForm.get('cardForm').disable();
        this.paymentForm.get('cardForm').updateValueAndValidity();
        this.paymentForm.get('eftForm').disable();
        this.paymentForm.get('eftForm').updateValueAndValidity();
    };
    PaymentEntryFormComponent.prototype.paymentAmountValidator = function () {
        var _this = this;
        return function (control) {
            return control.value > _this.balanceDue ? { amountGreater: true } : null;
        };
    };
    PaymentEntryFormComponent.prototype.onTogglePaymentMethod = function (method) {
        var _a;
        var flag = !this.paymentMethod[method];
        this.paymentMethod = (_a = {
                eft: false,
                cc: false
            },
            _a[method] = flag,
            _a);
        this.storedBankAccounts.forEach(function (bank) { return delete bank['selected']; });
        this.storedCreditCards.forEach(function (card) { return delete card['selected']; });
        this.paymentForm.get('paymentOptionSelected').setValue('');
        if (method === 'eft') {
            if (flag) {
                this.paymentForm.get('paymentOptionSelected').setValue('eft');
            }
            this.enableBankForm();
        }
        else if (method === 'cc') {
            if (flag) {
                this.paymentForm.get('paymentOptionSelected').setValue('cc');
            }
            this.enableCardForm();
        }
    };
    PaymentEntryFormComponent.prototype.onContinue = function () {
        if (this.paymentForm.valid) {
            if (this.policyDetails.cancelPending !== "Yes" && SCHEDULED_PAY_PLANS.includes(this.billingDetails.payplan)) {
                this.areYouSurePopup = true;
            }
            else {
                this.doContinue();
            }
        }
        else {
            this.formValidator.validateAllFormFields(this.paymentForm);
        }
    };
    PaymentEntryFormComponent.prototype.doContinue = function () {
        var _this = this;
        this.submitted = true;
        this.updateSafeToNavigate(true);
        this.isReviewInProgress = true;
        merge(this.cardEntry$, this.savedCardEntry$, this.electronicEntry$, this.savedBankEntry$, this.invalidEntry$).subscribe(function () { return (_this.isReviewInProgress = false); });
    };
    PaymentEntryFormComponent.prototype.doNeverMind = function () {
        var app = sessionStorage.getItem(PAYMENT_SOURCE);
        if (!app || app.toLowerCase() === 'eservice') {
            this.isSafeToNavigate = true;
            this.router.navigate(['/eservice/home'], { relativeTo: this.route });
        }
        else {
            this.areYouSurePopup = false;
        }
    };
    PaymentEntryFormComponent.prototype.onCancel = function () {
        sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);
    };
    PaymentEntryFormComponent.prototype.showHelpText = function (key) {
        this.helpFlags = tslib_1.__assign({}, this.helps);
        this.helpFlags[key] = true;
    };
    PaymentEntryFormComponent.prototype.hideHelpText = function () {
        this.helpFlags = tslib_1.__assign({}, this.helps);
    };
    PaymentEntryFormComponent.prototype.closeImportantMessage = function () {
        sessionStorage.setItem("showPayImpMsg", "Y");
        this.showPaymentImpMsg = false;
    };
    PaymentEntryFormComponent.prototype.contains = function (a, obj) {
        for (var i = 0; i < a.length; i++) {
            if (a[i] === obj) {
                return true;
            }
        }
        return false;
    };
    PaymentEntryFormComponent.prototype.onAddNewCard = function () {
        this.resetAllCardForm();
        this.disableAllCardForm();
        this.paymentForm.get('cardForm').enable();
        this.paymentForm.get('saveThisCard').enable();
        this.paymentForm.get('cardForm').updateValueAndValidity();
        this.addNewCard = true;
        this.fillUserInformation(null);
    };
    PaymentEntryFormComponent.prototype.onAddNewBank = function () {
        this.resetAllBankForm();
        this.disableAllBankForm();
        this.paymentForm.get('eftForm').enable();
        this.paymentForm.get('saveThisBank').enable();
        this.paymentForm.get('eftForm').updateValueAndValidity();
        this.addNewBank = true;
        this.fillUserInformation(null);
    };
    PaymentEntryFormComponent.prototype.savedPaymentMethodChanged = function (item, paymentType) {
        var key = item.key, value = item.value;
        this.paymentForm.get(key).markAsTouched();
        this.paymentForm.get(key).setValue(value);
        if (paymentType === 'cc') {
            this.resetAllBankForm();
            this.disableAllBankForm();
            var wallet = this.storedWalletMap[value];
            var savedCardType = wallet.transType ? wallet.transType.toUpperCase() : '';
            this.cvvLength = (savedCardType === 'AMEX'.toUpperCase()) ? 4 : 3;
            this.paymentForm.controls['savedCardSecurityCode'].setValidators([Validators.required, Validators.minLength(this.cvvLength), Validators.maxLength(this.cvvLength)]);
            this.paymentForm.get('savedCardSecurityCode').markAsUntouched();
            this.paymentForm.get('savedCardSecurityCode').setValue(null);
            this.paymentForm.get('savedCardSecurityCode').updateValueAndValidity();
        }
        else if (paymentType === 'eft') {
            this.resetAllCardForm();
            this.disableAllCardForm();
        }
    };
    PaymentEntryFormComponent.prototype.showHideNoteSection = function (event) {
        this.showNote = !event;
    };
    PaymentEntryFormComponent.prototype.disableAllBankForm = function () {
        this.paymentForm.get('eftForm').disable();
        this.paymentForm.get('eftSavedPaymentMethod').disable();
        this.paymentForm.get('saveThisBank').disable();
    };
    PaymentEntryFormComponent.prototype.resetAllBankForm = function () {
        this.paymentForm['eftForm'] = this.bankFormProvider.getForm();
        this.paymentForm.get('eftSavedPaymentMethod').reset();
        this.paymentForm.get('saveThisBank').reset();
        this.addNewBank = false;
    };
    PaymentEntryFormComponent.prototype.enableBankForm = function () {
        this.resetAllCardForm();
        this.disableAllCardForm();
        if (this.storedBankAccounts.length === 0) {
            this.onAddNewBank();
        }
        else {
            this.enableManagedBankForm();
        }
    };
    PaymentEntryFormComponent.prototype.enableManagedBankForm = function () {
        this.resetAllBankForm();
        this.disableAllBankForm();
        this.paymentForm.get('eftSavedPaymentMethod').enable();
        this.addNewBank = false;
    };
    PaymentEntryFormComponent.prototype.disableAllCardForm = function () {
        this.paymentForm.get('cardForm').disable();
        this.paymentForm.get('ccSavedPaymentMethod').disable();
        this.paymentForm.get('saveThisCard').disable();
        this.paymentForm.get('savedCardSecurityCode').disable();
    };
    PaymentEntryFormComponent.prototype.resetAllCardForm = function () {
        this.paymentForm['cardForm'] = this.cardFormProvider.getForm();
        this.paymentForm.get('ccSavedPaymentMethod').reset();
        this.paymentForm.get('saveThisCard').reset();
        this.paymentForm.get('savedCardSecurityCode').reset();
        this.addNewCard = false;
    };
    PaymentEntryFormComponent.prototype.enableCardForm = function () {
        this.resetAllBankForm();
        this.disableAllBankForm();
        if (this.storedCreditCards.length === 0) {
            this.onAddNewCard();
        }
        else {
            this.enableManagedCardForm();
        }
    };
    PaymentEntryFormComponent.prototype.enableManagedCardForm = function () {
        this.resetAllCardForm();
        this.disableAllCardForm();
        this.paymentForm.get('ccSavedPaymentMethod').enable();
        this.paymentForm.get('savedCardSecurityCode').enable();
        this.addNewCard = false;
    };
    PaymentEntryFormComponent.prototype.autoSelectForm = function (paymentD) {
        if (paymentD) {
            if (paymentD.paymentDetails['cardForm'] && paymentD.paymentDetails['cardForm']['cardNumber']) {
                this.enableCardForm();
            }
            else if (paymentD.paymentDetails['ccSavedPaymentMethod']) {
                this.enableManagedCardForm();
            }
            else if (paymentD.paymentDetails.eftForm && paymentD.paymentDetails.eftForm.accountNumber) {
                this.enableBankForm();
            }
            else if (paymentD.paymentDetails['eftSavedPaymentMethod']) {
                this.enableManagedBankForm();
            }
            this.paymentForm.patchValue(tslib_1.__assign({}, paymentD.paymentDetails));
        }
        delete this.paymentD;
    };
    PaymentEntryFormComponent.prototype.onContinueToPaymentForm = function () {
        this.showIntermediateRegisterationForm = false;
    };
    PaymentEntryFormComponent.prototype.onRegistrationSubmitted = function (eventResponse) {
        if (eventResponse && eventResponse.responseCode === "REGISTRATION_SUCCESS") {
            console.log("REGISTRATION_SUCCESS");
            sessionStorage.removeItem("qPayEserviceEnroll");
            sessionStorage.removeItem(PAYMENT_SOURCE);
            this.updateSafeToNavigate(true);
            this.router.navigate(['../intermediateRegistrationComplete'], {
                queryParams: { 'sourcePage': 'QPAY_ENTRY' },
                relativeTo: this.route
            });
        }
        else if (eventResponse && eventResponse.responseCode === 'EXISTING') {
            sessionStorage.removeItem("qPayEserviceEnroll");
            sessionStorage.removeItem(PAYMENT_SOURCE);
            this.updateSafeToNavigate(true);
            this.router.navigate(['/eservice/login'], {
                relativeTo: this.route,
                state: {
                    email: eventResponse.userName
                }
            });
        }
        else if (eventResponse && eventResponse.responseCode === 'NEW_POLICY') {
            sessionStorage.removeItem("qPayEserviceEnroll");
            sessionStorage.removeItem(PAYMENT_SOURCE);
            this.updateSafeToNavigate(true);
            this.router.navigate(['/eservice/link-policies'], {
                relativeTo: this.route,
                state: {
                    email: eventResponse.userName
                }
            });
        }
        else {
            console.log("REGISTRATION_ERROR: " + eventResponse);
        }
    };
    return PaymentEntryFormComponent;
}(DiscardComponent));
export { PaymentEntryFormComponent };
