<div>
  <h1 class="es-h1 mt-4">Manage Payment Methods</h1>
  <ng-container *ngIf="isLoading; else loading">
    <div *ngFor="let card of storedCreditCards">
      <ng-container class="mt-4">
        <prcins-notification  [notification]="card.expiration !== ''? {type:'WARN'} : null">
          Your {{card.cardDisplayType}} ending in {{card.debitAccount}} is about to or has expired. <a [routerLink]="['../edit-card']" (click)="onEditCreditCard(card)"
          prcins-tealium-tracker="click" prcins-tealium-event="edit_expired_card" class='cursor-pointer font-bold text-es-blue-light'>Update Now.</a>
        </prcins-notification>
      </ng-container>
    </div>
    <ng-container class="mt-4">
      <prcins-notification [notification]="editWalletSuccess? {type:'INFO'} : null">
          Success! Your payment method has been updated.
      </prcins-notification>
    </ng-container>
    <ng-container class="mt-4">
      <p *ngIf="showChangePayplanLink" class="text-gray-700 mb-4">To manage or update your payment plan <a (click)="goToPayPlan()" class='cursor-pointer font-bold text-es-blue-light'>click here</a></p>
      <p class="text-gray-700 mb-4">The payment methods below will be available to use the next time you make a payment. 
        Any changes you make here will not affect your pay plan or the method of payment used for any automatic payments.</p>
    </ng-container>

    <prcins-card headerClass="bg-es-blue text-white" pt="pt-0 pb-4">
      <span header-left>Your Credit & Debit Cards</span>
      <div body>
        <ng-container>
          <div *ngFor="let card of storedCreditCards">
            <div class="border rounded border-gray-300 mt-4 p-4">
              <div class="flex justify-between items-center">
                <span class="font-whitney-semibold"><img class="mr-4 cardType" width="50" height="32" alt="" src="{{card.imageUrl}}">
                  {{card.cardDisplayType}} *****{{card.debitAccount}}</span>
                <prcins-tooltip>
                  <a class="cursor-pointer" (click)="toggleDefault(card)" tooltip-label>
                    <img class="mr-4 defaultStarIconImage" alt="" src="{{card.defaultImage}}">
                  </a>
                  <ng-container tooltip-text *ngIf="card.defaultInd !== 'Yes'">
                    <p class="w-64 ">
                      Click to set this as your default payment method.
                    </p>
                  </ng-container>
                  <ng-container tooltip-text *ngIf="card.defaultInd === 'Yes'">
                    <p class="w-64 ">
                      Click to remove this as your default payment method.
                    </p>
                  </ng-container>
                </prcins-tooltip>
              </div>
              <div *ngIf="card.expiration === ''" class="flex mt-4"><span>Expires</span>&nbsp;<span>{{card.expirationDate}}</span></div>
              <div *ngIf="card.expiration == 'expiring'" class="flex mt-4"><span>Expires</span>&nbsp;<span class="expRed">{{card.expirationDate}}</span></div>
              <div *ngIf="card.expiration == 'expired'" class="flex mt-4"><span class="expRed">Expired</span>&nbsp;<span>{{card.expirationDate}}</span></div>
              <div class="flex mt-4">
                <button class="es-btn-secondary mr-2" (click)="onEditCreditCard(card)">Edit</button>
                <button class="es-btn-secondary" (click)="onDeleteCreditCard(card)">Remove</button>
              </div>
            </div>
          </div>
          <div *ngIf="storedCreditCards === null || storedCreditCards.length === 0">
            <div class="noMethods">You have no saved cards</div>
          </div>
        </ng-container>
        <div class="flex flex-wrap-reverse">
          <button class="es-btn-primary w-full mt-4" (click)="addNewCard(policyNumber)" type="submit">Add New
            Credit/Debit Card</button>
        </div>
      </div>
    </prcins-card>
    <prcins-card headerClass="bg-es-blue text-white" pt="pt-0 pb-4">
      <span header-left>Your Bank Accounts</span>
      <div body>
        <ng-container>
          <div *ngFor="let bank of storedBankAccounts">
            <div class="border rounded border-gray-300 mt-4 p-4">
              <div class="flex justify-between items-center">
                <span class="font-whitney-semibold">{{bank.accountType}} ****{{bank.debitAccount}}</span>
                <prcins-tooltip>
                  <a class="cursor-pointer" (click)="toggleDefault(bank)" tooltip-label>
                    <img class="mr-4 defaultStarIconImage" alt="" src="{{bank.defaultImage}}">
                  </a>
                  <ng-container tooltip-text *ngIf="bank.defaultInd !== 'Yes'">
                    <p class="w-64 ">
                      Click to set this as your default payment method.
                    </p>
                  </ng-container>
                  <ng-container tooltip-text *ngIf="bank.defaultInd === 'Yes'">
                    <p class="w-64 ">
                      Click to remove this as your default payment method.
                    </p>
                  </ng-container>
                </prcins-tooltip>
              </div>
              <div>
                <div class="flex"><span>Routing ****{{bank.debitRouting.substring(bank.debitRouting.length - 4)}}</span>
                </div>
                <div class="flex"><span>Account ****{{bank.debitAccount}}</span></div>
                <div class="flex justify-center mt-4">
                  <!-- <button class="es-btn-secondary mr-2" (click)="onEditBank(bank)">Edit</button> -->
                  <button class="es-btn-secondary" (click)="onDeleteBank(bank)">Remove</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="storedBankAccounts === null || storedBankAccounts.length === 0">
            <div class="noMethods">You have no saved bank accounts</div>
          </div>
        </ng-container>
        <div class="flex flex-wrap-reverse">
          <button class="es-btn-primary w-full mt-4" (click)="addNewBank(policyNumber)" type="submit">Add New Bank
            Account</button>
        </div>
      </div>
    </prcins-card>
    <!-- <prcins-card headerClass="bg-es-blue text-white" pt="pt-0 pb-4">
      <span header-left>Your Pay Plan Information</span>
      <div body>
        <div class="border rounded border-gray-300 mt-4 p-4">
          <div class="flex items-center">
            <span><img class="mr-4" alt="" src="card.imageUrl"></span>
            <span class="font-whitney-semibold">card.cardName *****card.debitAccount</span>
            <span>card.defaultImage</span>
          </div>
          <div class="flex mt-4"><span>Expires</span>&nbsp;<span>card.expirationDate</span></div>
          <div class="flex justify-center mt-4">
            <button class="es-btn-secondary" (click)="changePayPlan()">Change Pay Plan</button>
          </div>
        </div>
      </div>
    </prcins-card> -->
  </ng-container>
  <ng-template #loading>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton numOfRows="3"></prcins-skeleton>
      </div>
    </prcins-card>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton numOfRows="3"></prcins-skeleton>
      </div>
    </prcins-card>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton numOfRows="3"></prcins-skeleton>
      </div>
    </prcins-card>
  </ng-template>
</div>
<prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <p class="text-center text-lg">Are you sure you want to cancel this transaction?</p>
    <div class="flex flex-wrap-reverse justify-between">
      <a class="es-link-secondary  w-half-2 mt-8  cursor-pointer" (click)="showGuardPopup = false">No</a>
      <button class="es-btn-primary w-half-2 mt-8 cursor-pointer" type="button"
        (click)="cancelTransaction()">Yes</button>
    </div>
  </div>
</prcins-es-popup>
<prcins-es-popup *ngIf="payPlanPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Change Payment Method</span>
  </div>
  <div header-right>
    <img class="cursor-pointer" height='10px' width='10px' (click)="payPlanPopup = false" src="assets/svgs/close-hamburger.svg">
  </div>
  <div body>
    <div class="flex justify-center items-center">If this payment method is used for automatic payments, you must update it on the pay plan page.</div>
    <div class="btns">
      <button class="es-btn-primary w-full mt-4" (click)="goToPayPlan()">Go to Pay Plan</button>
      <button class="es-btn-primary w-full mt-4" (click)="continueAction()">Continue</button>
    </div>
  </div>
</prcins-es-popup>
<prcins-es-popup *ngIf="removeCardPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <div class="flex justify-center items-center">Are you sure you want to delete this card? </div>
    <div class="flex justify-center items-center mt-4">
      <span><img class="mr-4 cardType" width="50" height="32" alt="" src="{{cardToRemove.imageUrl}}"></span>
      <span class="font-whitney-semibold"> *****{{cardToRemove.debitAccount}}</span>
    </div>
    <div class="btns">
      <button class="es-btn-primary w-full mt-4" (click)="doDeleteCard(cardToRemove)">Delete</button>
      <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="removeCardPopup = false">Cancel</a>
    </div>
  </div>
</prcins-es-popup>
<prcins-es-popup *ngIf="removeBankPopup" headerClass="bg-es-blue text-white">
  <div header-left>
    <span>Confirmation</span>
  </div>
  <div body>
    <div class="items-center">
      Are you sure you want to delete this bank account?<br/>
      <span class="font-whitney-semibold">{{bankToRemove.accountType}} ****{{bankToRemove.debitAccount}}</span>
      <!-- <div>
        <div class="flex"><span>Routing ****{{bankToRemove.debitRouting.substring(bankToRemove.debitRouting.length - 4)}}</span>
        </div>
        <div class="flex"><span>Account ****{{bankToRemove.debitAccount}}</span></div>
      </div> -->
    </div>
    <div class="btns">
      <button class="es-btn-primary w-full mt-4" (click)="doDeleteBank(bankToRemove)">Delete</button>
      <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="removeBankPopup = false">Cancel</a>
    </div>
  </div>
</prcins-es-popup>
<prcins-spinner *ngIf="showSpinner"></prcins-spinner>
