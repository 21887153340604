import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CardFormProviderService, DiscardComponent, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { Card } from '../model/wallet';

@Component({
  selector: 'prcins-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.css']
})
export class EditCardComponent extends DiscardComponent implements OnInit {
  cardForm: FormGroup;
  card: Card;
  policyNumber: string;
  showSpinner = false;
  showError = false;
  channel: string;
  generalPhone: string;
  agentInfo = {};
  cardNumberHelp: boolean;

  constructor(private cardFormProvider: CardFormProviderService,
    private managePaymentsProviderService: ManagePaymentsProviderService,
    private router: Router,
    private formValidator: FormValidatorService,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');

    this.cardForm = this.cardFormProvider.getEditCardForm(false);
    this.card = this.managePaymentsProviderService.data;
    this.cardForm.get('cardType').setValue(this.card.cardDisplayType);
    this.cardForm.get('expirationDate').setValue(this.card.expirationDate);
    this.cardForm.get('expirationMonth').setValue(this.card.expirationMonth);
    this.cardForm.get('expirationYear').setValue(this.card.expirationYear);
    this.setAgentInfo();
  }

  doEditCard() {

    // form Validation
    this.formValidator.validateAllFormFields(this.cardForm);

    if (this.cardForm.valid) {
      this.showSpinner = true;
      var editedCard = this.card; // copy existing card
      editedCard.debitAccount = "*" + editedCard.debitAccount;
      editedCard.cvv = this.cardForm.get('securityCode').value;
      editedCard.expirationDate = null;
      editedCard.expirationMonth = this.cardForm.get('expirationMonth').value;
      editedCard.expirationYear = this.cardForm.get('expirationYear').value;
      editedCard.creditAccount = this.policyNumber;
      editedCard.accountEmail = sessionStorage.getItem(USER_ID);
      editedCard.paymentType = this.card.paymentType;
      //editedCard.transType = "CARD";
      //editedCard.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
  
      this.managePaymentsProviderService.editWallet(editedCard).subscribe((response) => {
        if (response == null || response.status === 'success' && response.error === "") {
          this.navigateToManagePayments('true');
        } else {
          this.showSpinner = false;
          alert(response.status + ", " + response.errorMessage);
        }
      }, (error: HttpErrorResponse) => {
        this.httpErrorResponse();
      });
    }
  }

  navigateToManagePayments(isSuccess: string) {
    sessionStorage.setItem('editWalletSuccess', isSuccess);
    this.router.navigate(['../manage'], {
      relativeTo: this.route
    });
  }

  cancelTransaction() {
    this.navigateToManagePayments('false');
  }

  httpErrorResponse() {
    this.showSpinner = false;
    this.showError = true;
  }

  setAgentInfo() {
    let agentDetails = sessionStorage.agentInfo;
    if (agentDetails) {
      this.agentInfo = JSON.parse(agentDetails);
    }
    this.generalPhone = sessionStorage.getItem('general-phone');
    this.channel = sessionStorage.channel;
  }

}
