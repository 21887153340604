import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { REGISTRATION, LOGIN_URL, DOCS_URL, Message } from '@prcins/constants';
import { FormBuilder } from '@angular/forms';
import { IUserPolicy } from '../models/user-policy';
import { Apollo } from 'apollo-angular';
import { RegisteredPolicyQuery, MobileRegisteredPolicyQuery, ActiveAccountQuery, AllAssociatedPolicyQuery, ViewPolicyAsGuestQuery } from '../graphql/policy-schemas';
import { INotification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userLoggedIn$ = new Subject<boolean>();

  statusNotification$ = new Subject<INotification>();

  constructor(private apollo: Apollo, private http: HttpClient, private fb: FormBuilder) { }

  login(data, token): Observable<any> {

    const headers = { 'headers': { 'userid': data.userId, 'tkn': token } };

    const login$: Observable<any> = this.http.post(LOGIN_URL.authenticate, data, headers);
    login$.pipe(tap(result => {
      if (result.messageCode === 'LOGIN_SUCCESSFUL') {
        this.userLoggedIn$.next(true);
      } else {
        this.userLoggedIn$.next(false);
      }
    }));
    return login$;
  }

  accountService(url, data: IUserPolicy) {
    return this.http.post(url, data).pipe(catchError(() => of({
      messageCode: 'NO_POLICY_FOUND'
    })));
  }

  setupPolicy(url: string, registration): Observable<any> {
    return this.http.post<any>(url, registration);
  }
  getSecurityQuestion(userName: string): Observable<any> {
    return this.http.get(`${LOGIN_URL.securityQuestions}/${userName}/ACCOUNT`);
  }

  validateSecurityQuestion(data: any): Observable<any> {
    return this.http.post(
      `${LOGIN_URL.validateQuestion}/${data.email}/ACCOUNT`,
      data
    );
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(
      `${LOGIN_URL.resetPassword}/${data.email}/ACCOUNT`,
      data
    );
  }

  enrollEdocs(data: any): Observable<any> {
    return this.http.post(`${LOGIN_URL.enrollEdoc}`, data, {
    });
  }

  eDiscloserAudit(payload: any) {
    return this.http.post(LOGIN_URL.AuditUrl, payload);
  }

  handleRegistration(registration) {
    if (!Message[registration.messageCode]) {
      //sessionStorage.removeItem(REGISTRATION);
      return null;
    }
    switch (registration.messageCode) {
      case Message.CONTINUE_REGISTRATION:
        return '../../setup';
      case Message.REGISTRATION_SUCCESS:
        sessionStorage.removeItem(REGISTRATION);
        return true;
      case Message.DASHBOARD:
        sessionStorage.removeItem(REGISTRATION);
        location.href = 'http://plymouthrock.com';
        break;
      case Message.AUTHENTICATE:
        sessionStorage.removeItem(REGISTRATION);
        return '/eservice/register/setup/authenticate';
      case Message.LOGIN_SUCCESSFUL:
        sessionStorage.removeItem(REGISTRATION);
        this.userLoggedIn$.next(true);
        return 'eservice/policy/';
      case Message.LOGIN:
        sessionStorage.removeItem(REGISTRATION);
        return 'eservice/login';
      default:
        break;
    }
  }

  getActiveAccount(username?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ActiveAccountQuery,
        variables: {
          username: username
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.accountInfo)
      );
  }

  viewPolicyAsGuest(policyNumber?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: ViewPolicyAsGuestQuery,
        variables: {
          policyNumber: policyNumber.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => [data.viewPolicyAsGuest])
      );
  }

  getAllAssociatedPolicies(username?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: AllAssociatedPolicyQuery,
        variables: {
          username: username
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.policyAssociations.registeredPolicies)
      );
  }

  getRegisteredPolicies(username?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: RegisteredPolicyQuery,
        variables: {
          username: username
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.registeredPolicies)
      );
  }

  getMobileRegisteredPolicies(username?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: MobileRegisteredPolicyQuery,
        variables: {
          username: username
        },
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map(({ data }: any) => data.account.registeredPolicies)
      );
  }

  requestMobileIdCard(userId: String): Observable<any> {
    return this.http.get(
      `${DOCS_URL.requestMobile}/${userId}`);
  }

}
