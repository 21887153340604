import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { REGISTRATION, LOGIN_URL, DOCS_URL, Message } from '@prcins/constants';
import { FormBuilder } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { RegisteredPolicyQuery, MobileRegisteredPolicyQuery, ActiveAccountQuery, AllAssociatedPolicyQuery, ViewPolicyAsGuestQuery } from '../graphql/policy-schemas';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/forms";
var LoginService = /** @class */ (function () {
    function LoginService(apollo, http, fb) {
        this.apollo = apollo;
        this.http = http;
        this.fb = fb;
        this.userLoggedIn$ = new Subject();
        this.statusNotification$ = new Subject();
    }
    LoginService.prototype.login = function (data, token) {
        var _this = this;
        var headers = { 'headers': { 'userid': data.userId, 'tkn': token } };
        var login$ = this.http.post(LOGIN_URL.authenticate, data, headers);
        login$.pipe(tap(function (result) {
            if (result.messageCode === 'LOGIN_SUCCESSFUL') {
                _this.userLoggedIn$.next(true);
            }
            else {
                _this.userLoggedIn$.next(false);
            }
        }));
        return login$;
    };
    LoginService.prototype.accountService = function (url, data) {
        return this.http.post(url, data).pipe(catchError(function () { return of({
            messageCode: 'NO_POLICY_FOUND'
        }); }));
    };
    LoginService.prototype.setupPolicy = function (url, registration) {
        return this.http.post(url, registration);
    };
    LoginService.prototype.getSecurityQuestion = function (userName) {
        return this.http.get(LOGIN_URL.securityQuestions + "/" + userName + "/ACCOUNT");
    };
    LoginService.prototype.validateSecurityQuestion = function (data) {
        return this.http.post(LOGIN_URL.validateQuestion + "/" + data.email + "/ACCOUNT", data);
    };
    LoginService.prototype.resetPassword = function (data) {
        return this.http.post(LOGIN_URL.resetPassword + "/" + data.email + "/ACCOUNT", data);
    };
    LoginService.prototype.enrollEdocs = function (data) {
        return this.http.post("" + LOGIN_URL.enrollEdoc, data, {});
    };
    LoginService.prototype.eDiscloserAudit = function (payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload);
    };
    LoginService.prototype.handleRegistration = function (registration) {
        if (!Message[registration.messageCode]) {
            //sessionStorage.removeItem(REGISTRATION);
            return null;
        }
        switch (registration.messageCode) {
            case Message.CONTINUE_REGISTRATION:
                return '../../setup';
            case Message.REGISTRATION_SUCCESS:
                sessionStorage.removeItem(REGISTRATION);
                return true;
            case Message.DASHBOARD:
                sessionStorage.removeItem(REGISTRATION);
                location.href = 'http://plymouthrock.com';
                break;
            case Message.AUTHENTICATE:
                sessionStorage.removeItem(REGISTRATION);
                return '/eservice/register/setup/authenticate';
            case Message.LOGIN_SUCCESSFUL:
                sessionStorage.removeItem(REGISTRATION);
                this.userLoggedIn$.next(true);
                return 'eservice/policy/';
            case Message.LOGIN:
                sessionStorage.removeItem(REGISTRATION);
                return 'eservice/login';
            default:
                break;
        }
    };
    LoginService.prototype.getActiveAccount = function (username) {
        return this.apollo
            .watchQuery({
            query: ActiveAccountQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.accountInfo;
        }));
    };
    LoginService.prototype.viewPolicyAsGuest = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: ViewPolicyAsGuestQuery,
            variables: {
                policyNumber: policyNumber.toUpperCase()
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return [data.viewPolicyAsGuest];
        }));
    };
    LoginService.prototype.getAllAssociatedPolicies = function (username) {
        return this.apollo
            .watchQuery({
            query: AllAssociatedPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyAssociations.registeredPolicies;
        }));
    };
    LoginService.prototype.getRegisteredPolicies = function (username) {
        return this.apollo
            .watchQuery({
            query: RegisteredPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.registeredPolicies;
        }));
    };
    LoginService.prototype.getMobileRegisteredPolicies = function (username) {
        return this.apollo
            .watchQuery({
            query: MobileRegisteredPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.registeredPolicies;
        }));
    };
    LoginService.prototype.requestMobileIdCard = function (userId) {
        return this.http.get(DOCS_URL.requestMobile + "/" + userId);
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.FormBuilder)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
