import { Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminDeleteAccountComponent } from './admin-delete-account/admin-delete-account.component';
import { ContactEmailComponent } from './contact-email/contact-email.component';
import { LinkPolicyComponent } from './link-policy/link-policy.component';
import { AuthorizeGuard } from './guards/authorize.guard';
import { BalanceDetailsComponent } from './balance-details/balance-details.component';
import { MobileRedirectComponent } from './mobile-redirect/mobile-redirect.component';
import { ManageAlertPreferencesComponent } from 'libs/account/src/lib/manage-alerts-preferences/manage-alert-preferences.component';
import { AdditionalDriversInfoComponent } from '../app/additional-drivers-info/additional-drivers-info.component';
import { AdditionalDriversDetailsComponent } from '../app/additional-drivers-details/additional-drivers-details.component';
import { AdditionalDriversRspnComponent } from '../app/additional-drivers-rspn/additional-drivers-rspn.component';
import { QuestionnaireRenewalDriverErrorComponent } from './questionnaire-renewal-driver-error/questionnaire-renewal-driver-error.component';
import { MobileAppLandingComponent } from "./mobile-app-landing/mobile-app-landing.component";
import { MobileAppDownloadComponent } from "./mobile-app-download/mobile-app-download.component";
import { JpmMaintenanceComponent } from './jpm-maintenance/jpm-maintenance.component';
import { MobileAppRedirectComponent } from "./mobile-app-redirect/mobile-app-redirect.component";
var ɵ0 = { 'tealium': { 'subSection': 'eService' } }, ɵ1 = function () { return import("./../../../../libs/login/src/lib/login.module.ngfactory").then(function (m) { return m.LoginModuleNgFactory; }); }, ɵ2 = { 'tealium': 'admin' }, ɵ3 = { 'tealium': { 'subSection': 'Home' } }, ɵ4 = function () {
    return import("./../../../../libs/billing/src/lib/billing.module.ngfactory").then(function (m) { return m.BillingModuleNgFactory; });
}, ɵ5 = { 'tealium': { 'subSection': 'Payments' } }, ɵ6 = function () {
    return import("./../../../../libs/policy/src/lib/policy.module.ngfactory").then(function (m) { return m.PolicyModuleNgFactory; });
}, ɵ7 = { 'tealium': { 'subSection': 'Payments' } }, ɵ8 = function () {
    return import("./../../../../libs/documents/src/lib/documents.module.ngfactory").then(function (m) { return m.DocumentsModuleNgFactory; });
}, ɵ9 = { 'tealium': { 'subSection': 'Documents' } }, ɵ10 = function () {
    return import("./../../../../libs/address/src/lib/address.module.ngfactory").then(function (m) { return m.AddressModuleNgFactory; });
}, ɵ11 = { 'tealium': { 'subSection': 'Policy' } }, ɵ12 = function () {
    return import("./../../../../libs/claims/src/lib/claims.module.ngfactory").then(function (m) { return m.ClaimsModuleNgFactory; });
}, ɵ13 = { 'tealium': { 'subSection': 'Claims' } }, ɵ14 = function () {
    return import("./../../../../libs/payment/src/lib/payment.module.ngfactory").then(function (m) { return m.PaymentModuleNgFactory; });
}, ɵ15 = { 'tealium': { 'subSection': 'Payments:Make a Payment' } }, ɵ16 = function () {
    return import("./../../../../libs/account/src/lib/account.module.ngfactory").then(function (m) { return m.AccountModuleNgFactory; });
}, ɵ17 = { 'tealium': { 'subSection': 'Account' } }, ɵ18 = { 'tealium': { 'subSection': 'Link a Policy' } }, ɵ19 = function () {
    return import("./../../../../libs/edit-coverages/src/lib/edit-coverages.module.ngfactory").then(function (m) { return m.EditCoveragesModuleNgFactory; });
}, ɵ20 = { 'tealium': { 'subSection': 'Policy' } }, ɵ21 = function () {
    return import("./../../../../libs/add-driver/src/lib/add-driver.module.ngfactory").then(function (m) { return m.AddDriverModuleNgFactory; });
}, ɵ22 = function () {
    return import("./../../../../libs/add-driver/src/lib/add-driver.module.ngfactory").then(function (m) { return m.AddDriverModuleNgFactory; });
}, ɵ23 = function () {
    return import("./../../../../libs/mortgage/src/lib/mortgage.module.ngfactory").then(function (m) { return m.MortgageModuleNgFactory; });
}, ɵ24 = function () {
    return import("./../../../../libs/mortgage/src/lib/mortgage.module.ngfactory").then(function (m) { return m.MortgageModuleNgFactory; });
}, ɵ25 = function () {
    return import("./../../../../libs/mortgage/src/lib/mortgage.module.ngfactory").then(function (m) { return m.MortgageModuleNgFactory; });
}, ɵ26 = function () {
    return import("./../../../../libs/endorsement/src/lib/endorsement.module.ngfactory").then(function (m) { return m.EndorsementModuleNgFactory; });
}, ɵ27 = { 'tealium': { 'subSection': 'Policy' } }, ɵ28 = function () {
    return import("./../../../../libs/features/personal-auto/src/lib/features-personal-auto.module.ngfactory").then(function (m) { return m.FeaturesPersonalAutoModuleNgFactory; });
}, ɵ29 = function () {
    return import("./../../../../libs/quickpay/src/lib/quickpay.module.ngfactory").then(function (m) { return m.QuickpayModuleNgFactory; });
}, ɵ30 = { 'tealium': { 'subSection': 'Payments' } };
var routes = [
    {
        path: 'eservice',
        data: ɵ0,
        children: [
            {
                path: '',
                loadChildren: ɵ1
            },
            {
                path: 'admin/activity',
                component: AdminHomeComponent,
                canActivate: [AuthorizeGuard],
                data: ɵ2
            },
            {
                path: 'admin/activity/delete',
                canActivate: [AuthorizeGuard],
                component: AdminDeleteAccountComponent
            },
            {
                path: 'mobile/redirect',
                component: MobileRedirectComponent
            },
            {
                path: 'alerts/preferences/:userId',
                component: ManageAlertPreferencesComponent
            },
            {
                path: 'home',
                canActivate: [AuthorizeGuard],
                canActivateChild: [AuthorizeGuard],
                component: HomeComponent,
                children: [
                    {
                        path: '',
                        component: LandingComponent,
                        data: ɵ3
                    },
                    {
                        path: 'billing',
                        loadChildren: ɵ4,
                        data: ɵ5
                    },
                    {
                        path: 'policy',
                        loadChildren: ɵ6,
                        data: ɵ7
                    },
                    {
                        path: 'documents',
                        loadChildren: ɵ8,
                        data: ɵ9
                    },
                    {
                        path: 'address',
                        loadChildren: ɵ10,
                        data: ɵ11
                    },
                    {
                        path: 'claims',
                        loadChildren: ɵ12,
                        data: ɵ13
                    },
                    {
                        path: 'payment',
                        loadChildren: ɵ14,
                        data: ɵ15
                    },
                    {
                        path: 'account',
                        loadChildren: ɵ16,
                        data: ɵ17
                    },
                    {
                        path: 'contact',
                        children: [
                            {
                                path: 'email',
                                component: ContactEmailComponent
                            }
                        ]
                    },
                    {
                        path: 'jpmmaintenance',
                        component: JpmMaintenanceComponent,
                    },
                    {
                        path: 'balance',
                        children: [
                            {
                                path: 'details',
                                component: BalanceDetailsComponent
                            }
                        ]
                    },
                    {
                        path: 'link-a-policy',
                        component: LinkPolicyComponent,
                        data: ɵ18
                    },
                    {
                        path: 'edit-coverages',
                        loadChildren: ɵ19,
                        data: ɵ20
                    },
                    {
                        path: 'add-driver',
                        loadChildren: ɵ21,
                    },
                    {
                        path: 'edit-driver',
                        loadChildren: ɵ22,
                    },
                    {
                        path: 'add-mortgage',
                        loadChildren: ɵ23,
                    },
                    {
                        path: 'edit-mortgage',
                        loadChildren: ɵ24,
                    },
                    {
                        path: 'replace-mortgage',
                        loadChildren: ɵ25,
                    },
                    {
                        path: 'endorsement',
                        loadChildren: ɵ26,
                        data: ɵ27
                    },
                    {
                        path: 'auto-endorsement',
                        loadChildren: ɵ28,
                    }
                ]
            },
            {
                path: 'app-landing', component: MobileAppLandingComponent
            },
            {
                path: 'app-download', component: MobileAppDownloadComponent
            },
            {
                path: 'app-redirect', component: MobileAppRedirectComponent
            },
            {
                path: 'app', redirectTo: 'app-download', pathMatch: 'full'
            }
        ]
    },
    {
        path: 'qpay',
        loadChildren: ɵ29,
        data: ɵ30
    },
    { path: 'eway/route/local/ui', redirectTo: 'eservice', pathMatch: 'full' },
    {
        path: 'questionnaire/renewal',
        children: [{
                path: 'driver',
                component: AdditionalDriversInfoComponent
            },
            {
                path: 'driver/review',
                canActivate: [AuthorizeGuard],
                component: AdditionalDriversDetailsComponent
            },
            {
                path: 'driver/confirmation',
                canActivate: [AuthorizeGuard],
                component: AdditionalDriversRspnComponent
            },
            {
                path: 'driver/error',
                component: QuestionnaireRenewalDriverErrorComponent
            }]
    },
    {
        path: '', redirectTo: 'eservice', pathMatch: 'full'
    },
    {
        path: '**', redirectTo: 'eservice', pathMatch: 'full'
    }
];
var EserviceRoutingModule = /** @class */ (function () {
    function EserviceRoutingModule() {
    }
    return EserviceRoutingModule;
}());
export { EserviceRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30 };
